import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledH1 = styled.h1`
  text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
`

const HomeHeroSectionUpdate = () => (
  <div style={{ display: "grid" }}>
    <StaticImage
      style={{
        gridArea: "1/1",
        height: 400,
      }}
      width={2200}
      layout="constrained"
      alt=""
      src="../../images/header/header-bg.jpg"
      placeholder='tracedSVG'
      formats={["auto", "webp", "avif"]}
    />
    <div
      style={{
        gridArea: "1/1",
        position: "relative",
        placeItems: "center",
        display: "grid",
      }}
    >
      <div className='w-full max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto'>
        <div className='w-11/12 flex flex-col items-center mx-auto'>
          <StyledH1 className='text-3xl md:text-5xl font-extrabold text-white uppercase text-center'>
            <span className='leading-tight'>IT support and strategy that helps small to medium businesses scale with confidence and ease</span>
          </StyledH1>

          <Link to="/testimonials">
            <button className="bg-red-600 hover:bg-red-700 text-white font-display px-8 py-4 text-xl md:text-2xl font-semibold tracking-wide rounded mt-8 shadow-lg">
              Client Feedback
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default HomeHeroSectionUpdate