import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import HomeHeroSectionUpdate from "../components/sections/home-hero-section-update"
import HomeIntroSectionNewVersion from "../components/sections/home-intro-section-new-version"
import HomeChecklistSection from "../components/sections/home-checklist-section"
import HomeDavidSection from "../components/sections/home-david-section"
import HomeCtaSection from "../components/sections/home-cta-section"
import CapabilitiesSection from "../components/sections/capabilities-section"
import MultipleStatusCountersSectionNew from "../components/sections/MultipleStatusCountersSectionNew"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Fully Managed IT Network Support"
    />

    <HomeHeroSectionUpdate />
    <HomeIntroSectionNewVersion />
    <MultipleStatusCountersSectionNew />
    <HomeChecklistSection />
    <HomeDavidSection />
    <HomeCtaSection />
    <CapabilitiesSection />
  </Layout>
)

export default IndexPage
